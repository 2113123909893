import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';

// const socket = io('http://64.23.229.96:2500');
const socket = io('/', { secure: true });

function Host() {
    const [winner, setWinner] = useState(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

    const questions = [
        "What is Mom’s favorite snack or treat?",
        "What is Mom’s favorite color?",
        "What is Mom’s favorite TV show or movie?",
        "What kind of music does Mom like to listen to the most?",
        "What is Mom’s favorite holiday or time of year?",
        "What’s Mom’s dream vacation destination?",
        "What is Mom’s favorite animal?",
        "What is the one chore Mom dislikes the most?",
        "What is something Mom is really good at?",
        "What’s Mom’s favorite meal or dish to cook?",
        "What is the one thing Mom always says to us?",
        "Where did Mom grow up?",
        "What is Mom’s proudest achievement?",
        "What is Mom’s favorite thing to do when she has free time?",
        "What is Mom’s favorite funny movie or show?",
        "What would Mom order if she went to her favorite restaurant?",
        "What’s the funniest thing Mom has ever done?",
        "What is Mom most likely to say when she’s frustrated?",
        "If Mom had to pick between pizza or tacos, which would she choose?",
        "What was Mom’s favorite subject in school?",
        "What is Mom’s favorite dessert?"
    ];


    useEffect(() => {
        socket.on('buzzer-winner', (username) => {
            setWinner(username);
        });

        socket.on('buzzer-reset', () => {
            setWinner(null);
        });

        return () => {
            socket.off('buzzer-winner');
            socket.off('buzzer-reset');
        };
    }, []);

    const handleNextQuestion = () => {
        // Reset the buzzer and winner before sending the new question
        socket.emit('reset-buzzer');
        setWinner(null);

        // Get the next question
        const question = questions[currentQuestionIndex];

        // Send the new question to contestants
        socket.emit('new-question', question);

        // Update the question index for the next round
        setCurrentQuestionIndex((prevIndex) => (prevIndex + 1) % questions.length);
    };



    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-200">
            <h1 className="text-4xl font-bold mb-8">Host Panel</h1>

            <div className="bg-white p-6 rounded-lg shadow-md w-3/4 mb-8">
                <h2 className="text-xl font-semibold">Current Question:</h2>
                {currentQuestionIndex === 0 ? (
                    <p className="mt-2 text-lg italic">No question displayed yet.</p>
                ) : (
                    <p className="mt-2 text-lg">
                        {questions[(currentQuestionIndex - 1 + questions.length) % questions.length]}
                    </p>
                )}
            </div>

            {winner ? (
                <h2 className="text-2xl font-bold text-green-600 mb-4">
                    {winner} buzzed first!
                </h2>
            ) : (
                <h2 className="text-2xl font-bold mb-4">Waiting for someone to buzz...</h2>
            )}

            <div className="flex space-x-4">
                <button
                    onClick={handleNextQuestion}
                    className="bg-green-500 text-white font-bold py-2 px-4 rounded hover:bg-green-600 transition duration-300"
                >
                    Next Question
                </button>
            </div>
        </div>
    );
}

export default Host;
