import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import UserSelection from './UserSelection';
import Contestant from './Contestant';
import Host from './Host';
import Header from './Header';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<UserSelection />} />
        <Route path="/contestant/:username" element={<Contestant />} />
        <Route path="/host" element={<Host />} />
      </Routes>
    </Router>
  );
}

export default App;
