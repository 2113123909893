import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import io from 'socket.io-client';

const socket = io('/', { secure: true });

function Contestant() {
    const { username } = useParams();
    const [isWinner, setIsWinner] = useState(false);
    const [buzzerDisabled, setBuzzerDisabled] = useState(true); // Initially disabled
    const [question, setQuestion] = useState(null);
    const [hasBuzzed, setHasBuzzed] = useState(false);
  
    useEffect(() => {
      socket.on('buzzer-winner', (winner) => {
        setBuzzerDisabled(true);
        if (winner === username) {
          setIsWinner(true);
        } else {
          setIsWinner(false);
        }
      });
  
      socket.on('buzzer-reset', () => {
        setIsWinner(false);
        setBuzzerDisabled(true); // Disable buzzer until a new question is received
        setHasBuzzed(false);
        setQuestion(null); // Clear the question
      });
  
      socket.on('new-question', (newQuestion) => {
        setQuestion(newQuestion);
        // Enable buzzer when a new question is received
        setBuzzerDisabled(false);
        setIsWinner(false);
        setHasBuzzed(false);
      });
  
      return () => {
        socket.off('buzzer-winner');
        socket.off('buzzer-reset');
        socket.off('new-question');
      };
    }, [username]);
  
    const handleBuzzer = () => {
      if (!buzzerDisabled) {
        socket.emit('buzzer-pressed', username);
        setHasBuzzed(true);
        setBuzzerDisabled(true);
      }
    };
  
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <h1 className="text-3xl font-bold mb-4">Hello, {username}!</h1>
  
        {question ? (
          <div className="bg-white p-6 rounded-lg shadow-md w-3/4 mb-8">
            <h2 className="text-xl font-semibold">Question:</h2>
            <p className="mt-2 text-lg">{question}</p>
          </div>
        ) : (
          <p className="text-lg mb-8 italic">Waiting for the next question...</p>
        )}
  
        <button
          onClick={handleBuzzer}
          disabled={buzzerDisabled}
          className={`text-white font-bold py-4 px-8 rounded-full text-2xl transition duration-300 ${
            hasBuzzed
              ? isWinner
                ? 'bg-green-500'
                : 'bg-red-500'
              : !buzzerDisabled
              ? 'bg-blue-500 hover:bg-blue-600'
              : 'bg-gray-400 cursor-not-allowed'
          }`}
        >
          {hasBuzzed ? (isWinner ? 'You buzzed first!' : 'You buzzed in') : 'Press Buzzer'}
        </button>
      </div>
    );
  }
  
  export default Contestant;