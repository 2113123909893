import React from 'react';
import { useNavigate } from 'react-router-dom';

function UserSelection() {
  const navigate = useNavigate();

  const handleSelection = (username) => {
    if (username === 'Dad') {
      navigate('/host');
    } else {
      navigate(`/contestant/${username}`);
    }
  };

  const users = ['Paolo', 'Cai', 'Tobey', 'Dad'];

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-500 to-purple-600">
      <h1 className="text-4xl font-bold text-white mb-8">Select Your User</h1>
      <div className="grid grid-cols-2 gap-4">
        {users.map((user) => (
          <button
            key={user}
            onClick={() => handleSelection(user)}
            className="bg-white text-black font-semibold py-2 px-4 rounded shadow-lg hover:bg-gray-200 transition duration-300"
          >
            {user === 'Dad' ? `${user} (Host)` : user}
          </button>
        ))}
      </div>
    </div>
  );
}

export default UserSelection;
