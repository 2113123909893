import React from 'react';

function Header() {
  return (
    <header className="w-full py-4 bg-gradient-to-r from-purple-600 to-blue-500 shadow-md">
      <h1 className="text-center text-white text-3xl font-bold">Game Show Buzzer</h1>
    </header>
  );
}

export default Header;
